import React from 'react';
import { ChakraProvider, ColorModeScript, ThemeConfig, extendTheme } from '@chakra-ui/react';

export const themeConfig: ThemeConfig = {
    initialColorMode: 'system',
    useSystemColorMode: false,
};

const theme = extendTheme({ config: themeConfig });

type ThemeProviderProps = {
    children: React.ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => (
    <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={themeConfig.initialColorMode} />
        {children}
    </ChakraProvider>
);

export default ThemeProvider;
