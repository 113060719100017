import React from "react";
import logo from "./beerjs.svg";

import ThemeProvider from "./theme";
import { Box, Heading, Icon, Image } from "@chakra-ui/react";
import { VscGithubInverted } from "react-icons/vsc";
import { PiTelegramLogo, PiInstagramLogo } from "react-icons/pi";

const Github = () => <Icon marginX="2" boxSize="8" as={VscGithubInverted} color="#343433" />;
const Telegram = () => <Icon marginX="2" boxSize="8" as={PiTelegramLogo} color="#343433" />;
const Instagram = () => <Icon marginX="2" boxSize="8" as={PiInstagramLogo} color="#343433" />;

function App() {
  return (
    <ThemeProvider>
      <Box
        alignItems="center"
        backgroundColor="#f9d627"
        flexDirection="column"
        justifyContent="center"
        minH="100vh"
        verticalAlign="center"
        display="flex"
      >
        <Image src={logo} alt="BeerJS Barcelona" h="300" />
        <Heading color="#343433" fontWeight="normal" fontFamily="Helvetica" marginTop="5">
          BeerJS <strong>BCN</strong>
        </Heading>
        <Box marginTop="5">
          <a target="_blanc" href="https://github.com/beerjs/bcn">
            <Github />
          </a>
          <a target="_blanc" href="https://t.me/beerjs_bcn">
            <Telegram />
          </a>
          <a target="_blanc" href="https://instagram.com/beerjsbcn">
            <Instagram />
          </a>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
